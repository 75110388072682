import { Button } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import EssentialFeatures from "../TextComponents/EssentialFeatures";
import HowItWorks from "../TextComponents/HowItWorks";
import FAQs from "../TextComponents/FAQs";
import { useLocation } from "react-router-dom";

export default () => {
  const history = useHistory();
  const location = useLocation();
  return (
    <footer>
      {(location.pathname === "/" || location.pathname.includes("create") )&& (
        <div className="flex flex-col md:flex-row  bg-slate-200 text-gray-400 justify-center w-full gap-4 md:gap-60 py-10 px-4 mt-8">
          <EssentialFeatures />
        </div>
      )}

      {(location.pathname === "/" || location.pathname.includes("create")) && (
        <div className="flex flex-col md:flex-row bg-cyan-900 text-gray-400 justify-center w-full gap-4 md:gap-60 py-10 px-4">
          <HowItWorks />
        </div>
      )}

      {(location.pathname === "/" || location.pathname.includes("create")) && (
        <div className="flex flex-col md:flex-row bg-slate-100 text-gray-400 justify-center w-full gap-4 md:gap-60 py-10 px-4">
          <FAQs />
        </div>
      )}
      <div className="flex flex-col bg-slate-800 items-center justify-center pb-4">
        <div className="flex flex-col md:flex-row bg-slate-800 text-gray-400 justify-center w-full py-5 gap-4 md:gap-60 px-4">
          <div className="flex flex-col w-96">
            <p className=" font-bold text-white mb-2">About Us</p>
            <p>
              Step into our online matrimonial biodata maker with open arms! 🌟
              We're excited to guide you through the process of crafting your
              biodata. As a team of dedicated professionals, we're committed to
              making this experience not only simple but also enjoyable for you.
            </p>
          </div>

          <div className="flex flex-col">
            <p className=" font-bold text-white mb-2">Support</p>
            <div
              className=" cursor-pointer hover:underline"
              onClick={() => history.push("terms-of-service")}
            >
              Terms of Service
            </div>
            <div
              className="cursor-pointer hover:underline"
              onClick={() => history.push("/privacy-policy")}
            >
              Privacy Policy
            </div>
            <div
              className="cursor-pointer hover:underline"
              onClick={() => history.push("/refund-policy")}
            >
              Refund Policy
            </div>
            <div
              className="cursor-pointer hover:underline"
              onClick={() => history.push("/shipping-policy")}
            >
              Shipping Policy
            </div>
            <div
              className="cursor-pointer hover:underline"
              onClick={() => history.push("/contact-us")}
            >
              Contact Us
            </div>
          </div>
        </div>

        <div className="text-gray-500 text-sm">
          Copyright 2024 - biodatamaker.co.in
        </div>
      </div>
    </footer>
  );
};
