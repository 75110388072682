import React from "react";
import DiamondRoundedIcon from "@mui/icons-material/DiamondRounded";
import ColorLensRoundedIcon from "@mui/icons-material/ColorLensRounded";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";

export default () => {
  return (
    <div className="flex max-w-5xl w-full flex-col items-center justify-center pb-8">
      <p
        className="md:text-4xl w-60 md:w-full text-xl text-center 
        font-bold max-w-4xl md:mb-10 mb-2 text-gray-800 whitespace-normal abc-normal"
      >
        Frequently Asked Questions
      </p>
      <div class="flex flex-row w-full flex-wrap mx-2">
        <div class="w-full flex flex-row flex-wrap gap-4 items-center justify-center px-4 py-2">
          <div class="mb-2 w-35rem bg-gray-200 rounded-md py-2">
            <div class="font-semibold w-full text-black  py-2 px-4">
              Is registration required to use the Marriage Biodata Maker?
            </div>

            <p className="px-4 py-1 abc-normal text-gray-900">
              No, our Marriage Biodata Maker does not require any registration.
              You can start creating your biodata right away without any sign-up
              process.
            </p>
          </div>
          <div class="mb-2 w-35rem bg-gray-200 rounded-md py-2">
            <div class="font-semibold text-black w-full py-2 px-4">
              How many templates are available to choose from?
            </div>

            <p className="px-4 py-1 abc-normal text-gray-900">
              No, our Marriage Biodata Maker does not require any registration.
              You can start creating your biodata right away without any sign-up
              process.
            </p>
          </div>
          <div class="mb-2 w-35rem bg-gray-200 rounded-md py-2">
            <div class="font-semibold text-black w-full  py-2 px-4">
              Is it possible to edit my biodata after creating it?
            </div>

            <p className="px-4 py-1 abc-normal text-gray-900">
              We prioritize user privacy and data security. By not storing any
              information, we ensure your data remains confidential. However,
              this means that once the biodata is downloaded, it cannot be
              modified.
            </p>
          </div>

          <div class="mb-2 w-35rem bg-gray-200 rounded-md py-2">
            <div class="font-semibold text-black w-full  py-2 px-4">
              How can I add new fields to my biodata?
            </div>

            <p className="px-4 py-1 abc-normal text-gray-900">
              With our biodata builder, adding new fields is simple. You can
              include additional sections or fields to accommodate specific
              information or div that are important to you.
            </p>
          </div>

          <div class="mb-2 w-35rem bg-gray-200 rounded-md py-2">
            <div class="font-semibold text-black w-full  py-2 px-4">
              Can I preview my customized biodata before finalizing it?
            </div>

            <p className="px-4 py-1 abc-normal text-gray-900">
              Yes, before generating the final biodata, you can preview your
              customized version to ensure everything looks as desired. This
              allows you to make any necessary adjustments before completing the
              process.
            </p>
          </div>

          <div class="mb-2 w-35rem bg-gray-200 rounded-md py-2">
            <div class="font-semibold text-black w-full  py-2 px-4">
              How can I reach out to you for queries or feedback?
            </div>

            <p className="px-4 py-1 abc-normal text-gray-900">
              We welcome your queries and feedback! You can easily get in touch
              with us by dropping an email at{" "}
              <a
                className=" text-teal-500"
                href="mailto: supbiodatamaker@gmail.com"
              >
                supbiodatamaker@gmail.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
