import React, { useRef, useState, useEffect, lazy, Suspense } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toast";
import Dropzone from "react-dropzone";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Cropper from "./Cropper";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import classnames from "classnames";
import { Select, MenuItem, InputLabel, OutlinedInput } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";

import { useTranslation } from "react-i18next";

const allowedFileTypes = ["jpg", "jpeg", "png", "JPG", "PNG", "JPEG"];

const TemplateSelect = lazy(() => import("./TemplateSelect"));

export default () => {
  const { t, i18n } = useTranslation();

  const rashi = [
    t("mesh"),
    t("varishabha"),
    t("mithuna"),
    t("karka"),
    t("simha"),
    t("kanya"),
    t("tula"),
    t("vrischika"),
    t("dhanur"),
    t("makara"),
    t("kumbha"),
    t("meena"),
  ];

  const defaultBioData = [
    {
      title: t("personalSection"),
      fields: [
        {
          title: t("name"),
          value: "",
          placeholder: t("enterName"),
        },
        {
          title: t("dob"),
          value: "",
          placeholder: "DD/MM/YYYY",
        },
        {
          title: t("timeB"),
          value: "",
          placeholder: "hh:mm: aa",
        },
        {
          title: t("pob"),
          value: "",
          placeholder: `${t("enter")} ${t("pob")}`,
        },
        {
          title: t("rashi"),
          value: "",
          options: rashi,
          placeholder: t("selectRashi"),
        },
        {
          title: t("gotra"),
          value: "",
          placeholder: `${t("enter")} ${t("gotra")}`,
        },
        {
          title: t("nakshatra"),
          value: "",
          placeholder: `${t("enter")} ${t("nakshatra")}`,
        },
        {
          title: t("complexion"),
          value: "",
          placeholder: t("selectComplexion"),
        },
        {
          title: t("height"),
          value: "",
          placeholder: `${t("enter")} ${t("height")}`,
        },
        {
          title: t("education"),
          value: "",
          placeholder: `${t("enter")} ${t("education")}`,
        },
        {
          title: t("occupation"),
          value: "",
          placeholder: `${t("enter")} ${t("occupation")}`,
        },
      ],
    },
    {
      title: t("familySec"),
      fields: [
        {
          title: t("fathersName"),
          value: "",
          placeholder: `${t("enter")} ${t("fathersName")}`,
        },
        {
          title: t("fatherOcc"),
          value: "",
          placeholder: `${t("enter")} ${t("fatherOcc")}`,
        },
        {
          title: t("mothersName"),
          value: "",
          placeholder: `${t("enter")} ${t("mothersName")}`,
        },
        {
          title: t("motherOccupation"),
          value: "",
          placeholder: `${t("enter")} ${t("motherOccupation")}`,
        },
        {
          title: t("brothersName"),
          value: "",
          placeholder: `${t("enter")} ${t("brothersName")}`,
        },
        {
          title: t("brothersOccupation"),
          value: "",
          placeholder: `${t("enter")} ${t("brothersOccupation")}`,
        },
        {
          title: t("sistersName"),
          value: "",
          placeholder: `${t("enter")} ${t("sistersName")}`,
        },
        {
          title: t("sistersOccupation"),
          value: "",
          placeholder: `${t("enter")} ${t("sistersOccupation")}`,
        },
      ],
    },
    {
      title: t("contactDetails"),
      fields: [
        {
          title: t("contactPerson"),
          value: "",
          placeholder: `${t("enter")} ${t("contactPerson")}`,
        },
        {
          title: t("contactNumber"),
          value: "",
          placeholder: `${t("enter")} ${t("contactNumber")}`,
        },
        {
          title: t("residentialAddress"),
          value: "",
          placeholder: `${t("enter")} ${t("residentialAddress")}`,
        },
      ],
    },
  ];

  const savedData = JSON.parse(localStorage.getItem("savedData")) || [
    ...defaultBioData,
  ];
  const [sections, setSections] = useState(savedData);
  const [selectTemplate, setSelectTemplate] = useState(false);
  const [imageSrc, setImageSrc] = React.useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const fileUploadRef = useRef(null);

  const updateSection = (title, fields) => {
    sections.forEach((section) => {
      if (section.title === title) {
        section.fields = fields;
      }
    });
    localStorage.setItem("savedData", JSON.stringify(sections));
    setSections([...sections]);
  };

  const handleShiftUp = (sectionToChange, index) => {
    const olderFields = sectionToChange.fields;
    const f1 = olderFields[index - 1];
    const f2 = olderFields[index];
    const slice1 = olderFields.slice(0, index - 1);
    const slice2 = olderFields.slice(index + 1);
    const newFields = [...slice1, f2, f1, ...slice2];
    updateSection(sectionToChange.title, newFields);
  };

  const handleShiftDown = (sectionToChange, index) => {
    const olderFields = sectionToChange.fields;
    const f1 = olderFields[index];
    const f2 = olderFields[index + 1];
    const slice1 = olderFields.slice(0, index);
    const slice2 = olderFields.slice(index + 2);
    const newFields = [...slice1, f2, f1, ...slice2];
    updateSection(sectionToChange.title, newFields);
  };

  const handleValueChange = (sectionToChange, index, value) => {
    const { fields, title } = sectionToChange;
    fields[index].value = value;
    updateSection(title, fields);
  };

  const handleTitleChange = (sectionToChange, index, titleValue) => {
    const { fields, title } = sectionToChange;
    fields[index].title = titleValue;
    updateSection(title, fields);
  };

  const handleFileUpload = async (acceptedFiles, file) => {
    setImageSrc(file);
  };

  const handleSelectTemplate = () => {
    document
    .getElementById("create-biodata")
    .scrollIntoView({ behavior: "smooth" });
    setSelectTemplate(!selectTemplate);

  };

  const handleDeleteField = (sectionToChange, index) => {
    const { fields, title } = sectionToChange;
    fields.splice(index, 1);
    updateSection(title, fields);
  };

  const handleAddField = (sectionToChange) => {
    const { fields, title } = sectionToChange;
    fields.push({
      title: "",
      titlePlaceHolder: t("enterFieldName"),
      value: "",
      placeholder: t("enterValue"),
      newField: true,
      id: "newField" + fields.length,
    });
    updateSection(title, fields);
  };

  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];

    if (selectTemplate || imageSrc) {
      html.classList.add("lock-scroll");
    } else {
      html.classList.remove("lock-scroll");
    }
    return () => {
      html.classList.remove("lock-scroll");
    };
  }, [selectTemplate, imageSrc]);

  i18n.on("languageChanged", () => {
    const rashi = [
      t("mesh"),
      t("varishabha"),
      t("mithuna"),
      t("karka"),
      t("simha"),
      t("kanya"),
      t("tula"),
      t("vrischika"),
      t("dhanur"),
      t("makara"),
      t("kumbha"),
      t("meena"),
    ];
    const defaultBioData = [
      {
        title: t("personalSection"),
        fields: [
          {
            title: t("name"),
            value: "",
            placeholder: t("enterName"),
          },
          {
            title: t("dob"),
            value: "",
            placeholder: "DD/MM/YYYY",
          },
          {
            title: t("timeB"),
            value: "",
            placeholder: "hh:mm: aa",
          },
          {
            title: t("pob"),
            value: "",
            placeholder: `${t("enter")} ${t("pob")}`,
          },
          {
            title: t("rashi"),
            value: "",
            options: rashi,
            placeholder: t("selectRashi"),
          },
          {
            title: t("gotra"),
            value: "",
            placeholder: `${t("enter")} ${t("gotra")}`,
          },
          {
            title: t("nakshatra"),
            value: "",
            placeholder: `${t("enter")} ${t("nakshatra")}`,
          },
          {
            title: t("complexion"),
            value: "",
            placeholder: t("selectComplexion"),
          },
          {
            title: t("height"),
            value: "",
            placeholder: `${t("enter")} ${t("height")}`,
          },
          {
            title: t("education"),
            value: "",
            placeholder: `${t("enter")} ${t("education")}`,
          },
          {
            title: t("occupation"),
            value: "",
            placeholder: `${t("enter")} ${t("occupation")}`,
          },
        ],
      },
      {
        title: t("familySec"),
        fields: [
          {
            title: t("fathersName"),
            value: "",
            placeholder: `${t("enter")} ${t("fathersName")}`,
          },
          {
            title: t("fatherOcc"),
            value: "",
            placeholder: `${t("enter")} ${t("fatherOcc")}`,
          },
          {
            title: t("mothersName"),
            value: "",
            placeholder: `${t("enter")} ${t("mothersName")}`,
          },
          {
            title: t("motherOccupation"),
            value: "",
            placeholder: `${t("enter")} ${t("motherOccupation")}`,
          },
          {
            title: t("brothersName"),
            value: "",
            placeholder: `${t("enter")} ${t("brothersName")}`,
          },
          {
            title: t("brothersOccupation"),
            value: "",
            placeholder: `${t("enter")} ${t("brothersOccupation")}`,
          },
          {
            title: t("sistersName"),
            value: "",
            placeholder: `${t("enter")} ${t("sistersName")}`,
          },
          {
            title: t("sistersOccupation"),
            value: "",
            placeholder: `${t("enter")} ${t("sistersOccupation")}`,
          },
        ],
      },
      {
        title: t("contactDetails"),
        fields: [
          {
            title: t("contactPerson"),
            value: "",
            placeholder: `${t("enter")} ${t("contactPerson")}`,
          },
          {
            title: t("contactNumber"),
            value: "",
            placeholder: `${t("enter")} ${t("contactNumber")}`,
          },
          {
            title: t("residentialAddress"),
            value: "",
            placeholder: `${t("enter")} ${t("residentialAddress")}`,
          },
        ],
      },
    ];
    setSections(defaultBioData);
  });

  return (
    <>
      {!selectTemplate && (
        <div
          className="flex flex-col w-full items-center justify-center max-w-7xl overflow-hidden px-4"
          style={{
            height: `${!!imageSrc ? "0" : "100%"}`,
          }}
        >
          {
            <div className="flex flex-col-reverse md:flex-row justify-between w-full overflow-hidden mt-10 gap-5">
              <div className="w-full">
                {sections.map((section, sectionIndex) => {
                  const { fields, title } = section;
                  return (
                    <div
                      key={title}
                      className="w-full items-center justify-center mb-4"
                    >
                      <div className="text-black abc-regular text-2xl font-bold mb-2">
                        {title}
                      </div>
                      {fields.map((field, index) => {
                        const {
                          title,
                          value,
                          placeholder,
                          newField,
                          titlePlaceHolder,
                          id,
                          options,
                        } = field;
                        return (
                          <div
                            key={id || title + index}
                            className="flex flex-row items-center mb-6"
                          >
                            <div className="flex flex-col w-full gap-2 font-semibold">
                              <input
                                id={title}
                                type="text"
                                placeholder={titlePlaceHolder}
                                disabled={!newField}
                                value={title}
                                onChange={(e) => {
                                  handleTitleChange(
                                    section,
                                    index,
                                    e.target.value
                                  );
                                }}
                                className={classnames(
                                  "h-14 text-gray-600 border text-lg border-black w-full rounded-md px-4  outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2",
                                  { "bg-gray-200": !newField }
                                )}
                              />
                              {title !== t("rashi") &&
                                title !== t("dob") &&
                                title !== t("timeB") && (
                                  <input
                                    id={title + value}
                                    type="text"
                                    value={value}
                                    placeholder={placeholder}
                                    onChange={(e) => {
                                      handleValueChange(
                                        section,
                                        index,
                                        e.target.value
                                      );
                                    }}
                                    className="h-14 text-black w-full border text-lg border-black rounded-md px-4 outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 "
                                  />
                                )}
                              {title === t("rashi") && (
                                <>
                                  <Select
                                    id="demo-simple-select"
                                    className="h-14 text-black w-full border text-lg border-black rounded-md outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 "
                                    value={value}
                                    displayEmpty
                                    label={placeholder}
                                    input={<OutlinedInput />}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    renderValue={(selected) => {
                                      if (selected.length === 0) {
                                        return (
                                          <p className="text-black text-lg text-opacity-40">
                                            {placeholder}
                                          </p>
                                        );
                                      }

                                      return selected;
                                    }}
                                    onChange={(e) => {
                                      handleValueChange(
                                        section,
                                        index,
                                        e.target.value
                                      );
                                    }}
                                  >
                                    {options.map((option) => (
                                      <MenuItem key={option} value={option}>
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </>
                              )}

                              {title === t("dob") && (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale={"en-gb"}
                                >
                                  <DatePicker
                                    format="DD/MM/YYYY"
                                    defaultValue={dayjs(value, "DD/MM/YYYY")}
                                    onChange={(value) => {
                                      handleValueChange(
                                        section,
                                        index,
                                        value.format("DD/MM/YYYY")
                                      );
                                    }}
                                  />
                                </LocalizationProvider>
                              )}

                              {title === t("timeB") && (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    defaultValue={dayjs(value, "hh:mm a")}
                                    onChange={(value) => {
                                      handleValueChange(
                                        section,
                                        index,
                                        value.format("hh:mm a")
                                      );
                                    }}
                                    renderValue={(value) => {
                                      return (
                                        <p className="text-black text-lg text-opacity-40">
                                          {value}
                                        </p>
                                      );
                                    }}
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                  />
                                </LocalizationProvider>
                              )}
                            </div>
                            <div className="flex flex-col gap-5 ml-4">
                              <KeyboardArrowUpIcon
                                color="secondary"
                                className="cursor-pointer"
                                onClick={() => {
                                  if (index > 0) handleShiftUp(section, index);
                                }}
                              />
                              <KeyboardArrowDownIcon
                                color="secondary"
                                className="cursor-pointer"
                                onClick={() => {
                                  if (index < fields.length - 1)
                                    handleShiftDown(section, index);
                                }}
                              />
                            </div>
                            <div
                              onClick={() => {
                                handleDeleteField(section, index);
                              }}
                              className="ml-2 bg-red-200 rounded-full p-2 hover:bg-red-400 cursor-pointer"
                            >
                              <DeleteIcon color="gray" />
                            </div>
                          </div>
                        );
                      })}
                      <div
                        onClick={() => {
                          handleAddField(section);
                        }}
                        className="cursor-pointer font-bold text-blue-800 px-4 py-2 rounded bg-slate-100 w-fit -mt-4"
                      >
                        + Add more fields
                      </div>
                    </div>
                  );
                })}
                <div className="flex flex-col-reverse md:flex-row w-full justify-end abc-regular text-xl gap-5 text-black mb-5 px-4">
                  <button
                    onClick={() => {
                      localStorage.removeItem("savedData");
                      setSections([...defaultBioData]);
                      document
                        .getElementById("create-biodata")
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                    class="rounded-2xl px-4 py-4 bg-gray-200 hover:bg-gray-300 duration-300"
                  >
                    Reset form
                  </button>
                  <button
                    onClick={handleSelectTemplate}
                    class="rounded-2xl px-16 py-4 bg-blue-800 text-green-100 hover:bg-blue-900 duration-300"
                  >
                    Select Template
                  </button>
                </div>
              </div>
              <div className="flex items-center justify-center md:sticky md:self-start md:top-0">
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    acceptedFiles.forEach((file) => {
                      const reader = new FileReader();
                      reader.onabort = () => toast.error("Invalid Image");
                      reader.onerror = () =>
                        toast.error("Invalid Image Try Again");
                      reader.onload = () => {
                        const fileName = file.name.split(".")[1];
                        if (allowedFileTypes.includes(fileName) || !fileName) {
                          const binaryStr = reader.result;
                          handleFileUpload(fileName, binaryStr.trim());
                          setTimeout(() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }, 300);
                        } else {
                          toast.error("Invalid Image Try Again");
                        }
                      };
                      reader.readAsDataURL(file);
                    });
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input data-cy="urlFiltering-file" {...getInputProps()} />
                      <div
                        {...getRootProps()}
                        className="flex flex-col"
                        ref={fileUploadRef}
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="flex flex-col h-96 w-72 justify-center mt-4 rounded-lg border bg-gray-300 border-gray-800 items-center">
                          {!croppedImage ? (
                            <>
                              <div className="pb-2">
                                <AccountBoxIcon
                                  sx={{ fontSize: 120 }}
                                  color="gray"
                                />
                              </div>
                              <p className="mx-4 abc-normal  text-xl font-normal text-dope-th text-center  text-gray-900">
                                Click to Add your photo (Max 3mb)
                              </p>
                            </>
                          ) : (
                            <>
                              <img className="rounded-lg" src={croppedImage} />
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setImageSrc(null);
                                  setCroppedImage(null);
                                }}
                                className="absolute flex items-center justify-center h-6 w-6 top-2 -right-0 rounded-full cursor-pointer bg-red-500 overflow-visible"
                              >
                                <CloseOutlinedIcon
                                  fontSize="small"
                                  color="white"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          }
          {imageSrc && (
            <div>
              <Cropper
                imageSrc={imageSrc}
                setImageSrc={setImageSrc}
                setCroppedImage={setCroppedImage}
              />
            </div>
          )}
        </div>
      )}
      {selectTemplate && (
        <Suspense>
          <TemplateSelect
            handleSelectTemplate={handleSelectTemplate}
            sections={sections}
            updateSections={setSections}
            croppedImage={croppedImage}
          />
        </Suspense>
      )}
    </>
  );
};
