import React, { useEffect, useRef, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { Button } from "@mui/material";
import { useParams, useHistory } from "react-router-dom";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { toast } from "react-toast";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";

export default () => {
  const [isPaid, setIsPaid] = useState();
  const [loading, setLoading] = useState(true);
  const { order_id } = useParams();
  const history = useHistory();
  const dmRef = useRef(null);

  const checkOrder = async () => {
    try {
      setLoading(true);
      const result = await axios.get(
        "https://ivk1m2m4x8.execute-api.ap-south-1.amazonaws.com/default/order/" +
          order_id
      );
      if (result.data.order_status === "PAID") {
        setIsPaid(true);
        setTimeout(() => {
          dmRef?.current?.click();
        }, 100);

        if (localStorage.getItem("converted") !== "true") {
          window.gtag_report_conversion();
        }
        localStorage.setItem("converted", "true");
      } else setIsPaid(false);

      setLoading(false);
    } catch (err) {
      toast.error("Something went wrong!. Please refresh the page.");
      setIsPaid(false);
      setLoading(false);
    }
  };

  const checkOrderRazor = async () => {
    try {
      setLoading(true);
      const result = await axios.get(
        "https://ivk1m2m4x8.execute-api.ap-south-1.amazonaws.com/default/order-razor/" +
          order_id
      );
      if (result.data.amount_paid === result.data.amount) {
        setIsPaid(true);
        setTimeout(() => {
          dmRef?.current?.click();
        }, 100);

        if (localStorage.getItem("converted") !== "true") {
          window.gtag_report_conversion();
        }
        localStorage.setItem("converted", "true");
      } else setIsPaid(false);

      setLoading(false);
    } catch (err) {
      toast.error("Something went wrong!. Please refresh the page.");
      setIsPaid(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    checkOrderRazor();
  }, []);

  return (
    <div className="h-full w-full flex flex-col items-center justify-start md:justify-center md:pb-32 pt-16 gap-6">
      {isPaid && !loading && (
        <>
          <div className="flex flex-col rounded-full p-4 items-center w-fit bg-blue-600 justify-center text-4xl md:text-5xl text-white">
            <DoneIcon fontSize="inherit" color="inherit" />
          </div>
          <p className="text-black font-bold text-2xl mt-4 text-center">
            Your Biodata has been downloaded.
          </p>

          <div className="flex mt-4 flex-row items-center justify-center gap-4">
            <div className="text-white text-sm md:text-xl text-bold">
              <Button
                variant="contained"
                color="primary"
                size="medium"
                id={"hqrt"}
              >
                <a
                  href={`https://user-saves.s3.ap-south-1.amazonaws.com/${order_id}.pdf`}
                  ref={dmRef}
                  download={"biodata.pdf"}
                >
                  Download Again
                </a>
              </Button>
            </div>
            <div className="text-white text-sm md:text-xl text-bold">
              <Button
                onClick={() => {
                  history.push("/");
                }}
                variant="contained"
                color="danger"
                size="medium"
              >
                Back
              </Button>
            </div>
          </div>
        </>
      )}
      {!isPaid && !loading && (
        <>
          <div className="flex flex-col rounded-full p-4 items-center w-fit bg-red-600 justify-center text-4xl md:text-5xl text-white">
            <ErrorOutlineOutlinedIcon fontSize="inherit" color="inherit" />
          </div>
          <p className="text-black font-bold text-xl mt-4 text-center">
            Payment failed please try again. If amount deducted please refresh
            this page after some time.
          </p>

          <div className="flex mt-4 flex-row items-center justify-center gap-4">
            <div className="text-white text-sm md:text-xl text-bold">
              <Button
                onClick={() => {
                  history.push("/");
                }}
                variant="contained"
                color="danger"
                size="medium"
              >
                Back
              </Button>
            </div>
          </div>
        </>
      )}

      {loading && (
        <div className="h-full flex items-center justify-center">
          <BeatLoader
            color={"black"}
            loading={loading}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      <div className="flex flex-row"></div>
    </div>
  );
};
