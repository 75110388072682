import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "../translations/en.json"
import mr from "../translations/mr.json"
import hn from "../translations/hn.json"

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    detection: {order: ['path'], lookupFromPathIndex:1},
    default: "english",
    fallbackLng: 'english',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      english:{ translation: en},
      marathi: { translation: mr},
      hindi: { translation: hn}
    }
  });

export default i18n;