import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from '../../assets/biglogo.png'


export default () => {
  const navigate = useHistory();
  const history = useHistory();
  const { i18n } = useTranslation();
  const languageMap = {
    marathi: "मराठी",
    hindi: "हिंदी",
    english: "English",
  };
  return (
    <header>
      <nav className="w-full py-8 flex flex-row bg-black items-center justify-between px-4 md:px-8">
        <div
          onClick={() => {
            navigate.push("/");
          }}
          className="text-3xl abc-regular font-bold md:ml-10 text-amber-50 cursor-pointer"
        >
          <img src={logo} alt="My logo" width={50} height={50} />
        </div>

        <div className="text-1xl flex flex-row mr-10 gap-4 font-bold">
          <button
            id="dropdownHoverButton"
            data-dropdown-toggle="dropdownHover"
            data-dropdown-trigger="hover"
            class="text-white bg-amber-950 hover:bg-amber-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
            type="button"
          >
            {languageMap[i18n.language] || "Language"}
            <svg
              class="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>

          <div
            id="dropdownHover"
            class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
          >
            <ul
              class="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefaultButton"
            >
              <li
                onClick={() => {
                  localStorage.removeItem("savedData");
                  i18n.changeLanguage("english");
                  history.push("/create/english");
                }}
              >
                <a
                  href="#"
                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  English
                </a>
              </li>
              <li
                onClick={() => {
                  localStorage.removeItem("savedData");
                  i18n.changeLanguage("hindi");
                  history.push("/create/hindi");
                }}
              >
                <a
                  href="#"
                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  हिंदी
                </a>
              </li>
              <li
                onClick={() => {
                  localStorage.removeItem("savedData");
                  i18n.changeLanguage("marathi");
                  history.push("/create/marathi");
                }}
              >
                <a
                  href="#"
                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  मराठी
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
