import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../../Utils/canvasUtils";
import { Slider, Box } from "@mui/material";

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

export default ({ imageSrc, setCroppedImage, setImageSrc }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(100);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    setTimeout(() => {
      document.getElementById("create-biodata").scrollIntoView();
    }, 100);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      setImageSrc(null);
    } catch (e) {
      console.error(e);
    }

    setTimeout(() => {
      document.getElementById("create-biodata").scrollIntoView();
    }, 100);
  };

  return (
    <>
      <div className="fixed top-0 left-0 m-auto w-full h-full overflow-hidden bg-slate-200 z-50">
        <div className="flex flex-col h-full  items-center justify-center">
          <div className="relative h-4/5 items-center justify-center md:w-1/3 md:h-1/3" style={{height: "80vh", width: "80vh"}}>
            <div className="flex flex-row w-full h-20 flex-nowrap">
              <Cropper
                image={imageSrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom / 100}
                aspect={3 / 4}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={(z) => setZoom(z * 100)}
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center py-4 w-full md:w-1/3 bg-white">
            <div className="flex flex-col justify-start items-start">
              <div className="flex flex-col">
                <p className="abc-regular text-black">Zoom {zoom}&#176;</p>
                <Box sx={{ width: 300 }}>
                  <Slider
                    value={zoom}
                    min={100}
                    max={300}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    onChange={(e, value) => setZoom(value)}
                  />
                </Box>
              </div>
              <div className="flex flex-col">
                <p className="abc text-black">Rotation {rotation}</p>
                <Box sx={{ width: 300 }}>
                  <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    onChange={(e, value) => setRotation(value)}
                  />
                </Box>
              </div>
            </div>
            <div className="flex flex-row w-full justify-around items-center">
              <button
                onClick={() => showCroppedImage()}
                class="rounded-xl w-40 py-1 bg-blue-800 text-green-100 hover:bg-blue-900 duration-300"
              >
                Crop Image
              </button>
              <button
                onClick={() => {
                  setImageSrc(null);

                  setTimeout(() => {
                    document.getElementById("create-biodata").scrollIntoView();
                  }, 100);
                }}
                class="rounded-xl w-40 py-1 bg-blue-800 text-green-100 hover:bg-blue-900 duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
