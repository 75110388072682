import React from "react";
import DiamondRoundedIcon from "@mui/icons-material/DiamondRounded";
import ColorLensRoundedIcon from "@mui/icons-material/ColorLensRounded";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";

export default () => {
  return (
    <div className="flex flex-col items-center justify-center pb-8">
      <p
        className="md:text-4xl w-60 md:w-full text-xl text-center 
        font-bold max-w-4xl md:mb-10 mb-4 text-gray-800 whitespace-normal abc-normal my-8"
      >
        Crucial Elements in Our Biodata Builder App
      </p>
      <div>
        <div className="flex flex-row flex-wrap gap-8 items-center justify-center">
          <div class="flex flex-col gap-3 items-center justify-center py-5 px-10 w-25rem h-80 bg-white text-gray-800 text-lg rounded-lg font-semibold border-gray-800 shadow-lg shadow-gray-400/70">
            <div className=" flex items-center justify-center rounded-full p-2 bg-pink-700">
              <DiamondRoundedIcon fontSize="large" color="white" />
            </div>
            <p className="text-center font-bold text-1xl">
              Attractive Biodata Templates
            </p>
            <p className="text-base text-center text-gray-600">
              Explore our sleek biodata templates, designed to help you impress
              and stand out effortlessly.
            </p>
          </div>
          <div class="flex flex-col gap-3 items-center justify-center py-5 px-10 w-25rem h-80 bg-white text-gray-800 text-lg rounded-lg font-semibold border-gray-800 shadow-lg shadow-gray-400/70">
            <div className=" flex items-center justify-center rounded-full p-2 bg-green-700">
              <SentimentSatisfiedAltRoundedIcon
                fontSize="large"
                color="white"
              />
            </div>
            <p className="text-center font-bold text-1xl">
              User-friendly Interface
            </p>
            <p className="text-base text-center text-gray-600">
              Enjoy a seamless experience with our easy-to-use interface,
              hassle-free PDF format biodata creation.
            </p>
          </div>
          <div class="flex flex-col gap-3 items-center justify-center py-5 px-10 w-25rem h-80 bg-white text-gray-800 text-lg rounded-lg font-semibold border-gray-800 shadow-lg shadow-gray-400/70">
            <div className=" flex items-center justify-center rounded-full p-2 bg-orange-700">
              <ColorLensRoundedIcon fontSize="large" color="white" />
            </div>
            <p className="text-center font-bold text-1xl">
              Streamlined Customization
            </p>
            <p className="text-base text-center text-gray-600">
              Effortlessly customize your personalized profile with our
              intuitive and friendly Biodata Builder Form.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
