import React from "react";
import Slider from "react-slick";
import './slick.min.css';
import { useTranslation } from "react-i18next";

export default () => {
  var settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
  };

  const randomArr = Array.apply(null, Array(6)).map(function (item, index) {
    const val = Math.floor(Math.random() * 9);
    if(val) return val
    return 4
  });

  const { t } = useTranslation();

  return (
    <section className="flex flex-col items-center justify-center text-center mt-6 md:mt-10 flex-wrap overflow-hidden">
      <p
        className="md:text-3xl w-60 md:w-full text-md text-center 
        font-bold max-w-4xl md:mb-10 mb-4  text-gray-800 whitespace-normal py-1"
      >
        {t("slideHeading")}
      </p>
      <div className="flex flex-wrap w-full gap-2 justify-center sm:flex md:hidden lg:hidden">
        {[...Array(4).keys()]
          .slice(1)
          .reverse()
          .map((value) => {
            return (
              <div>
                <img
                  src={`https://d16akdef0hveqs.cloudfront.net/bgs/b${value}.png`}
                  width={230} height={326}
                />
              </div>
            );
          })}
      </div>

      <div className="w-full hidden md:block">
        <Slider {...settings}>
          {randomArr.map((value) => {
            return (
              <div>
                <img
                  src={`https://d16akdef0hveqs.cloudfront.net/bgs/b${value}.png`}
                  width={352}
                  height={498}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};
