import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();
  return (
    <section className="w-full flex flex-cols md:flex-row mt-5 md:mt-20 items-center justify-between">
      <div className="flex flex-col">
        <div className="abc-regular text-2xl md:text-4xl font-bold  text-black md:w-96">
          <h1>{t("showCaseHeading")}</h1>
        </div>
        <p className="abc-regular text-gray-800 text-md font-bold md:text-xl md:w-35rem mt-5">
          {t("showCaseDesc")}
        </p>
        <div className="mt-6 ">
          <div className="mr-10 hidden md:block">
            <button
              onClick={() => {
                document
                  .getElementById("create-biodata")
                  .scrollIntoView({ behavior: "smooth" });
              }}
              class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800"
            >
              <span class="relative font-bold px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                Create Your Biodata Now
              </span>
            </button>
          </div>
        </div>
      </div>
      <div
        className="flex-row hover:scale-110 hidden md:flex"
        style={{ transition: "transform .5s" }}
      >
        <img
          className="-mr-10"
          style={{ transform: "rotate(-10deg)" }}
          src={"https://d16akdef0hveqs.cloudfront.net/bgs/b6.png"}
          width={298}
          height={421}
        />
        <img
          src={"https://d16akdef0hveqs.cloudfront.net/bgs/b3.png"}
          className="z-10 -mt-10"
          loading="lazy"
          width={298}
          height={421}
        />
        <img
          className="-ml-10"
          src={"https://d16akdef0hveqs.cloudfront.net/bgs/b1.png"}
          width={298}
          height={421}
          style={{ transform: "rotate(10deg)" }}
        />
      </div>
    </section>
  );
};
