import React from "react";

export default () => {
  return (
    <section id="how-it-works" className="flex flex-col items-center justify-center pb-8">
      <p
        className="md:text-4xl w-60 md:w-full text-xl text-center 
        font-bold max-w-4xl md:mb-10 mb-4 text-gray-200 whitespace-normal abc-normal my-8"
      >
        How it works ?
      </p>
      <div>
        <div className="flex flex-row flex-wrap gap-8 items-center justify-center">
          <div class="flex flex-col gap-3 items-center justify-center  px-10 w-25rem h-80 text-gray-800 text-lg rounded-lg font-semibold border-gray-800">
            <div className="flex items-center text-3xl text-orange-400 h-12 w-12 justify-center rounded-full p-4 bg-black">
              1
            </div>
            <p className="text-center text-white font-bold text-1xl">
              Input Your Details
            </p>
            <p className="text-base text-center text-gray-200">
              Effortlessly provide your personal, family, and contact details.
              Enhance your biodata by adding a profile photo for a more
              personalized touch.
            </p>
          </div>
          <div class="flex flex-col gap-3 items-center justify-center  px-10 w-25rem h-80 text-gray-800 text-lg rounded-lg font-semibold border-gray-800">
            <div className="flex items-center text-3xl text-orange-400 h-12 w-12 justify-center rounded-full p-4 bg-black">
              2
            </div>
            <p className="text-center text-white font-bold text-1xl">
              Choose the template
            </p>
            <p className="text-base text-center text-gray-200">
              Craft your biodata effortlessly with our professionally curated
              templates. Simply select the one that suits you best, and let us
              take care of the rest.
            </p>
          </div>
          <div class="flex flex-col gap-3 items-center justify-center  px-10 w-25rem h-80 text-gray-800 text-lg rounded-lg font-semibold border-gray-800">
            <div className="flex items-center text-3xl text-orange-400 h-12 w-12 justify-center rounded-full p-4 bg-black">
              3
            </div>
            <p className="text-center text-white font-bold text-1xl">
              Download PDF
            </p>
            <p className="text-base text-center text-gray-200">
              Your stunning biodata is now available to download in PDF format!
              Thank you for choosing our Marriage Biodata Maker for this
              important occasion.
            </p>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          document
            .getElementById("create-biodata")
            .scrollIntoView({ behavior: "smooth" });
        }}
        class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800"
      >
        <span class="relative font-bold px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
          Create You Biodata Now
        </span>
      </button>
    </section>
  );
};
