import "./App.css";
import HeaderJs from "./components/Header/index.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Showcase from "./components/Showcase/index.js";
import InfoHead from "./components/InfoHead/index.js";
import CreateBiodata from "./components/CreateBiodata/index.js";
import { ToastContainer } from "react-toast";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import React from "react";
import Footer from "./components/Footer/index.jsx";
import Download from "./components/Download/index.jsx";
import { lazy, Suspense } from "react";

const PrivacyPolicy = lazy(() =>
  import("./components/privacyPolicy/provacyPolicy.jsx")
);
const Terms = lazy(() =>
  import("./components/privacyPolicy/termsAndCondiions.jsx")
);
const RefundPolicy = lazy(() =>
  import("./components/privacyPolicy/refund.jsx")
);
const DeliveryPolicy = lazy(() =>
  import("./components/privacyPolicy/shipping.jsx")
);
const ContactUs = lazy(() =>
  import("./components/privacyPolicy/contact-us.jsx")
);

const theme = createTheme({
  palette: {
    primary: { main: "#00051F" },
    secondary: { main: "#000000" },
    gray: { main: "#808080" },
    green: { main: "#50C878" },
    damGray: { main: "#e8eaef" },
    danger: { main: "#8B0000" },
    pink: { main: "#FF81A0" },
    white: { main: "white" },
  },
});

const RealApp = () => {
  return (
    <>
      <div className="w-full flex flex-col items-center justify-center bg-gray-200 pb-6 md:pb-20 px-4">
        <div className="w-full max-w-7xl">
          <InfoHead />
        </div>
      </div>
      <div className="flex-col items-center justify-center bg-purple-50 pb-4 md:pb-20 flex px-4">
        <div style={{ maxWidth: "80rem" }}>
          <Showcase />
        </div>
      </div>
      <div className="w-full flex justify-center flex-col items-center  z-20">
        <div
          id={"create-biodata"}
          className="w-full h-20 flex flex-row items-center justify-center bg-gray-400"
        >
          <div className="flex items-center justify-center text-xl md:text-4xl text-center font-bold text-amber-50">
            Create Your Biodata
          </div>
        </div>
        <CreateBiodata />
      </div>
    </>
  );
};

export default () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div className="flex w-full flex-col h-screen overflow-x-hidden">
          <ToastContainer delay={3000} />
          <HeaderJs />
          <Suspense>
            <Switch>
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/terms-of-service" component={Terms} />

              <Route exact path="/refund" component={Download} />
              <Route exact path="/download/:order_id" component={Download} />

              <Route exact path="/refund-policy" component={RefundPolicy} />
              <Route exact path="/contact-us" component={ContactUs} />

              <Route exact path="/shipping-policy" component={DeliveryPolicy} />
              <Route exact path="/" component={RealApp} />
              <Route exact path="/create/marathi" component={RealApp} />
              <Route exact path="/create/english" component={RealApp} />
              <Route exact path="/create/hindi" component={RealApp} />
            </Switch>
          </Suspense>
          <Footer />
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
};
